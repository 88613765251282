<template>
  <div class="information">
    <h3 class="sub-title">About Wuhan, China</h3>
    <div class="container">
      <div class="title">
        <h3>Welcome to Wuhan, a beautiful city of China!</h3>
      </div>
      <p>
        Wuhan, China is a Millennium Cultural City and an International city located in the hinterland of central China. As the geographical core of China, Wuhan has the largest water, land and air transportation hub in China, with more than 600 bridges and tunnels, spanning east, west, north and south. The 10,000-ton giant ship can directly sail to Wuhan. Wuhan is a city of wetlands, where the Yangtze River meets the Han River. There are 165 rivers, 166 lakes, and 445 species of birds, where life continues to flourish. Wuhan is also a famous historical and cultural city, with the world-famous "Yellow Crane Tower" and "East Lake Greenway".
      </p>
      <div class="img-box">
        <img src="@/assets/img/about/wuhan.png" alt="">
      </div>

      <!-- 武汉国际博览中心 -->
      <h4>Wuhan INTERNATIONAL EXPO Center</h4>
      <p>
        The Wuhan International Expo Center, a world-class exhibition landmark, is located in the Sixin Ecology New Town of Wuhan. It is the largest, most functional and highest-specification conference center in Central China. It has an International Pavilion of 460,000 square meters, an International Conference Center of 100,000 square meters, a super-large conference hall of 6,000 square meters which can accommodate 4,000 people at the same time. There is also an 800-square-meter stepped international studio to meet the needs of international conferences.
      </p>
      <div class="img-box">
        <img src="@/assets/img/about/bolan.png" alt="">
      </div>
      <!-- 华中科技大学同济医学院附属协和医院 -->
      <h4>
        Wuhan Union Hospital
      </h4>
      <p>
        Wuhan Union Hospital Cardiovascular Surgery is one of the nation's top cardiovascular surgery centers. It is currently divided into six sub-specialties, namely congenital heart defect, adult heart disease, macrovascular disease, minimally invasive cardiac surgery, end-stage heart and lung disease, structural heart disease. It has performed more than 5500 surgeries, including more than 100 heart transplants per year.
        <br>
        Under the leadership of Professor Nianguo Dong, President of the Cardiovascular Surgeons Branch of Chinese Medical Doctor Association and Vice President of the Chinese Society for Thoracic and Cardiovascular Surgery, Wuhan Union Hospital Cardiovascular Surgery ranked 3th in Fudan Version of 2021 China Hospital Specialty Reputation Ranking.
      </p>
      <div class="img-box">
        <img src="@/assets/img/about/xiehe.png" alt="">
      </div>

      <p>
        We solemnly promise that we have the ability and confidence to make “ASCVTS 2024” an unforgettable event. We will do our best to run “ASCVTS 2024” and expand the influence of “ASCVTS” in China through the annual meeting. 
        <br>
        We sincerely invite you to join in the grand event on the Yangtze River in 2024.
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.information {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .container {
    font-family: "PublicSans Regular";
    font-size: 16px;
    .title {
      background: linear-gradient(73deg, rgba(63,136,163,1) 30%, rgba(88,46,104,1) 100%);
      text-align: center;
      color: #fff;
      font-size: 24px;
      margin-bottom: 20px;
      font-family: "Akrobat Black";
      h3 {
        padding: 8px;
        color: #fff;
      }
    }
    h4 {
      font-family: "Akrobat Black";
      font-weight: 700;
      position: relative;
      margin: 15px 0 15px;
      padding-left: 15px;
      border-left: 3px solid #e8bd55;
      font-size: 20px;
      line-height: 28px;
    }
    p {
      margin-bottom: 20px;
    }
    .img-box {
      margin: 0 auto;
      max-width: 600px;
      width: 80%;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      margin-bottom: 20px;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
